<template>
    <div>
        <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
                <template v-slot:header>
                    <h6 class="mb-0">Config deeplink</h6>
                </template>
                <b-row>
                    <b-col class="col-3">
                        <b-form-group label="Tiêu đề">
                            <b-form-input
                                v-model="input.title">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-right">
                        <b-button class="mr-2" variant="outline-primary" @click="getList(1)"
                        >Tìm kiếm
                        </b-button>
                        <b-button
                            class="mr-2"
                            variant="outline-primary"
                            @click="linkCreate()"
                        >Thêm mới
                        </b-button>
                    </b-col>
                </b-row>
                <hr/>
                <b-table
                    :items="items"
                    :fields="fields"
                    ref="table"
                    striped
                    hover
                    responsive
                    caption-top
                >
                    <template #cell(logo)="data">
                        <img v-bind:src="data.item.logo" width="100px" />
                    </template>

                    <template #cell(banner)="data">
                        <img v-bind:src="data.item.banner" width="100px" />
                    </template>

                    <template #cell(action)="data">
                        <router-link
                            :to="'/tools/config-deeplink/create?id=' + data.item.id"
                        >
                            <b-button style="margin-right: 10px;" pill variant="info" size="sm">Sửa</b-button>
                        </router-link>
                        <b-button
                            @click="destroy(data.item.id)"
                            pill
                            variant="danger"
                            size="sm"
                        >Xóa
                        </b-button>
                    </template>

                    <template #cell(created_at)="data">
                        <div>
                            {{ formatDate(data.item.created_at) }}
                        </div>
                    </template>

                    <template #cell(updated_at)="data">
                        <div>
                            {{ formatDate(data.item.updated_at) }}
                        </div>
                    </template>

                </b-table>
                <div class="mt-3">
                    <b-pagination-nav
                        v-model="currentPage"
                        :link-gen="createLinkPage"
                        :number-of-pages="paginate.totalPage"
                        use-router
                        first-number
                        last-number
                        align="right"
                    ></b-pagination-nav>
                </div>
            </b-card>
        </b-card-group>
    </div>
</template>
<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import Vue from "vue";
import Multiselect from "vue-multiselect";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

Vue.component("multiselect", Multiselect);
const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);
export default {
    mixins: [Common],
    data() {
        return {
            input: {
                title: null,
            },
            query: null,
            items: [],
            currentPage: 1,
            paginate: {
                limit: 25,
                page: 1,
                totalPage: 1,
                total: 0,
                skip: 0,
            },
            fields: [
                {
                    key: "id",
                    label: "ID",
                },
                {
                    key: "title",
                    label: "Tiêu đề",
                },
                {
                    key: "logo",
                    label: "Logo"
                },
                {
                    key: "banner",
                    label: "Banner"
                },
                {
                    key: "created_at",
                    label: "Thời gian tạo",
                },
                {
                    key: "updated_at",
                    label: "Thời gian sửa",

                }, {
                    key: "action",
                    label: "Hành động",

                },],
            listAllConfigFee: null,
        };
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Config deeplink", route: "configDeeplink"},
        ]);
    },
    methods: {
        linkCreate() {
            return this.$router.push({name: "configDeeplink-create"});
        },
        linkDetail(id) {
            this.$router.push("/tools/config-deeplink/view?id=" + id);
        },
        getList(page = 1) {
            this.items = [];
            let params = {...this.input};
            params.page = page;
            params.limit = 25;
            this.$bus.$emit("show-loading", true);
            CmsRepository.listDeeplink(params)
                .then((response) => {
                    if (response.data.error_code) {
                        this.notifyAlert("warn", response.message);
                        this.$bus.$emit("show-loading", false);
                        return;
                    }

                    response = response.data;
                    this.items = response.data.data;
                    this.paginate.total = response.data.total;
                    this.paginate.totalPage = response.data.last_page;
                    this.paginate.currentPage = response.data.current_page;
                    this.$bus.$emit("show-loading", false);
                    return;
                })
                .catch(() => {
                    this.$bus.$emit("show-loading", false);
                    alert("Có lỗi xảy ra");
                });
        },
        async destroy(id) {
            if (confirm("Bạn có chắc chắn muốn xóa")) {
                try {
                    this.$bus.$emit("show-loading", true);
                    let response = await CmsRepository.deleteDeeplink(id);
                    this.$bus.$emit("show-loading", false);
                    if (0 === response.data.error_code) {
                        this.$notify({
                            group: "foo",
                            type: "success",
                            title: response.data.message,
                        });
                        return this.getList(this.currentPage);

                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: response.data.message,
                        });
                        this.getList();
                    }
                } catch (e) {
                    this.$bus.$emit("show-loading", false);
                }
            }
        },
    },

    created() {
        this.query = this.$route.query;
        this.getList();
    },
    watch: {
        currentPage() {
            this.getList(this.currentPage);
        },
    },

};
</script>
